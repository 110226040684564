


















import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import PageBanner from "@/components/ui/PageBanner.vue";
import TechnologiesArticleNav from "@/components/project/TechnologiesArticleNav.vue";

import { technologies } from "@/mock/technologies";

@Component({
  components: {
    PageBanner,
    TechnologiesArticleNav
  }
})
export default class ServicesPage extends Vue {
  technologies = technologies;
}
